/*------------------------------------*
	
*------------------------------------*/
$blue-light: #2eaadd;
$blue: #1972b1;
$blue-dark: #103380;
$blue-darker: #15255a;
$purple: #61257f;
$purple-light: #911a7d;
$pink: #e12172;
$pink-light: #e86278;
$pink-lighter: #f3ae9e;

$colors: (
	"0": #2eaadd,
	"100": #1972b1,
	"200": #103380,
	"300": #15255a,
	"400": #61257f,
	"500": #911a7d,
	"600": #e12172,
	"700": #e86278,
	"800": #f3ae9e,
);

@each $color in $colors {
	$i: index($colors, $color);
	$key: nth($color, 1);
	$bgc: map-get($colors, $key);
		
	.border:nth-of-type(#{$i}) {
		z-index: $i;
		top: ($i - 1) * 11.111111111vh;
		width: calc(100% * #{$i});
		height: 100vh;
		background-color: $bgc;
		transform: translateX(var(--transform));
		will-change: transform;
	}
}

.border {
	position: absolute;
}

.borders {
	z-index: z("default", +1);
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	pointer-events: none;
}

.borders--under {
	z-index: z("default", -1);
}