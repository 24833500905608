// We need to import
// the variables first
// so they are available
// to use in the tools
@import "base/variables";

// Tools
@import "tools/functions";
@import "tools/mixins";
@import "tools/helpers";

// Debug
// @import "tools/debug-outline";

// Global styles
@import "base/ress"; // {ress|normalize|reset}
@import "base/global";

// Layout
@import "layout/grid";
@import "layout/grid-advanced";
@import "layout/main";

@import "cardini/borders";

html {
	min-height: 200vh;
	overflow: scroll;
}

.social {
	z-index: z("default", +2);
	position: fixed;
	right: 1rem;
	bottom: 1rem;
	
	a {
		display: inline-block;
		text-decoration: none;
		border-radius: 50%;
		overflow: hidden;
		
		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 100%;
			background-color: $blue-light;
			@include linear-gradient(180deg, $blue-light, $blue, $blue-dark, $blue-darker, $purple, $purple-light, $pink, $pink-light, $pink-lighter);
			border-radius: 50%;
			transform: translate(-50%, -50%) scale(0);
			transition: 0.8s transform $out-back;
		}
		
		&:hover {
			&::before {
				transform: translate(-50%, -50%) scale(1);
				transition: 0.8s transform $out-back;
			}
			
			svg {
				fill: $white;
				transition: 0.4s fill $out-expo;
			}
		}
	}
	
	svg {
		display: block;
		fill: #000;
		transition: 0.4s fill $out-expo;
	}
}

.logo {
	z-index: z("modal");
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: var(--opacity);
	transform: translate(-50%, -50%);
	will-change: opacity;
}


.contain {
	z-index: z("default", -1);
	padding-top: 1200px;
	padding-bottom: 5rem;
	text-transform: uppercase;
	color: #000;
	
	@media (min-height: 900px) {
		justify-content: flex-start;
	}
	
	@media #{md("smaller")} {
		padding-top: 1400px;
	}
}

.contain__inner {
	max-width: 840px;
	margin: auto;
	padding: 1rem;
	text-align: center;
	
	img {
		display: block;
		width: 100%;
		max-width: 100%;
		height: auto;
	}
}

$space: 0.4rem;
.title {
	margin-bottom: 0;
	font-size: 1.4rem;
	font-weight: 300;
	
	@media #{md("smaller")} {
		font-size: 2rem;
	}
}

.jc {
	margin: $space 0;
	font-size: 1.95rem;
	font-weight: 900;
	
	@media #{md("smaller")} {
		font-size: 2.75rem;
	}
}

.local {
	display: block;
	margin-bottom: $space * 8;
	font-size: 1rem;
	font-weight: 600;

	@media #{md("smaller")} {
		font-size: 1.4rem;
	}

	span {
		display: block;
		margin-top: $space;
		font-weight: 300;
		text-transform: initial;
	}
}

a.local {
	text-decoration: none;
	transition: 0.4s $out-expo color;

	&:hover {
		color: $blue-light;
		transition: 0.4s $out-expo color;
	}
}

.pix {
	font-weight: 400;
}

.contain__infos {
	margin-bottom: $space;
	font-size: 300;
}

.btn {
	display: inline-block;
	margin: $space * 2 0;
	padding: 1rem 2rem;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	color: $white;
	cursor: pointer;
	
	@include linear-gradient(120deg, $blue-light, $blue, $blue-dark, $blue-darker, $purple, $purple-light, $pink, $pink-light, $pink-lighter);
	
	&:hover {
		animation: 0.4s magic linear infinite;
	}
}

@keyframes magic {
	0% {
		@include linear-gradient(120deg, $blue-light, $blue, $blue-dark, $blue-darker, $purple, $purple-light, $pink, $pink-light, $pink-lighter);
	}
	
	11% {
		@include linear-gradient(120deg, $blue, $blue-dark, $blue-darker, $purple, $purple-light, $pink, $pink-light, $pink-lighter, $blue-light);
	}
	
	22% {
		@include linear-gradient(120deg, $blue-dark, $blue-darker, $purple, $purple-light, $pink, $pink-light, $pink-lighter, $blue-light, $blue);
	}
	
	33% {
		@include linear-gradient(120deg, $blue-darker, $purple, $purple-light, $pink, $pink-light, $pink-lighter, $blue-light, $blue, $blue-dark);
	}
	
	44% {
		@include linear-gradient(120deg, $purple, $purple-light, $pink, $pink-light, $pink-lighter, $blue-light, $blue, $blue-dark, $blue-darker);
	}
	
	55% {
		@include linear-gradient(120deg, $purple-light, $pink, $pink-light, $pink-lighter, $blue-light, $blue, $blue-dark, $blue-darker, $purple);
	}
	
	66% {
		@include linear-gradient(120deg, $pink, $pink-light, $pink-lighter, $blue-light, $blue, $blue-dark, $blue-darker, $purple, $purple-light);
	}
	
	77% {
		@include linear-gradient(120deg, $pink-light, $pink-lighter, $blue-light, $blue, $blue-dark, $blue-darker, $purple, $purple-light, $pink);
	}
	
	88% {
		@include linear-gradient(120deg, $pink-lighter, $blue-light, $blue, $blue-dark, $blue-darker, $purple, $purple-light, $pink, $pink-light);
	}
	
	99% {
		@include linear-gradient(120deg, $blue-light, $blue, $blue-dark, $blue-darker, $purple, $purple-light, $pink, $pink-light, $pink-lighter);
	}
}

#mc_embed_signup{
	padding-top: 5em;
	
	form {
		max-width: 320px;
		margin: auto;
	}
	
	.btn {
		z-index: z("default");
		height: 100%;
		margin: 0;
		padding: 0.5em 1em;
		@include linear-gradient(120deg, $blue-light, $blue, $blue-dark, $blue-darker, $purple, $purple-light, $pink, $pink-light, $pink-lighter);
	}
	
	.email {
		z-index: z("default");
		padding: 0.8em 1em;
		font-size: 12px;
		width: 100%;
	}
}

#mc_embed_signup_scroll {
	display: flex;
	align-items: center;
	justify-content: space-between;
	
	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		transform: translate(-50%, -50%);
		@include linear-gradient(120deg, $blue-light, $blue, $blue-dark, $blue-darker, $purple, $purple-light, $pink, $pink-light, $pink-lighter);
	}
	
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: calc(100% - 4px);
		height: calc(100% - 4px);
		background-color: $white;
		transform: translate(-50%, -50%);
	}
}

.btn img {
	display: none;
}



canvas {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}