/*------------------------------------*\
		Grid system (Bootstrap)
\*------------------------------------*/

.grid {
	width: $layout-main--width;
	max-width: $layout-main--max-width;
	margin-right: auto;
	margin-left: auto;
}

.grid__row {
	@include clearfix;
	margin-right: -$grid-gutter--smallest / 2;
	margin-left: -$grid-gutter--smallest / 2;

	// Media queries
	@media #{md("smaller")} {
		margin-right: -$grid-gutter--smaller / 2;
		margin-left: -$grid-gutter--smaller / 2;
	}

	@media #{md("small")} {
		margin-right: -$grid-gutter--small / 2;
		margin-left: -$grid-gutter--small / 2;
	}

	@media #{md("medium")} {
		margin-right: -$grid-gutter--medium / 2;
		margin-left: -$grid-gutter--medium / 2;
	}

	@media #{md("large")} {
		margin-right: -$grid-gutter--large / 2;
		margin-left: -$grid-gutter--large / 2;
	}

	@media #{md("larger")} {
		margin-right: -$grid-gutter--larger / 2;
		margin-left: -$grid-gutter--larger / 2;
	}
}


.grid,
[class*="grid__col-xxs--"],
[class*="grid__col-xs--"],
[class*="grid__col-s--"],
[class*="grid__col-m--"],
[class*="grid__col-l--"],
[class*="grid__col-xl--"] {
	min-height: 1px;
	padding-right: $grid-gutter--smallest / 2;
	padding-left: $grid-gutter--smallest / 2;

	// Media queries
	@media #{md("smaller")} {
		padding-right: $grid-gutter--smaller / 2;
		padding-left: $grid-gutter--smaller / 2;
	}

	@media #{md("small")} {
		padding-right: $grid-gutter--small / 2;
		padding-left: $grid-gutter--small / 2;
	}

	@media #{md("medium")} {
		padding-right: $grid-gutter--medium / 2;
		padding-left: $grid-gutter--medium / 2;
	}

	@media #{md("large")} {
		padding-right: $grid-gutter--large / 2;
		padding-left: $grid-gutter--large / 2;
	}

	@media #{md("larger")} {
		padding-right: $grid-gutter--larger / 2;
		padding-left: $grid-gutter--larger / 2;
	}
}

[class*="grid__col-xxs--"],
[class*="grid__col-xs--"],
[class*="grid__col-s--"],
[class*="grid__col-m--"],
[class*="grid__col-l--"],
[class*="grid__col-xl--"] {
	float: left;
}



/*------------------------------------*\
		Smallest view
\*------------------------------------*/

[class*="grid__col-xxs--"] { display: block; }

.grid__col-xxs--left  { float: left;   }
.grid__col-xxs--right { float: right;  }
.grid__col-xxs--0     { display: none; }

// Generate all smallest classes
@for $i from 1 through $grid--columns {
	.grid__col-xxs--#{$i} {
		width: $i * 100% / $grid--columns;
	}
}

// Generate all push classes
.grid__col-xxs-push--0 {
	margin-left: auto;
}

@for $i from 1 through $grid--columns {
	.grid__col-xxs-push--#{$i} {
		margin-left: $i * 100% / $grid--columns;
	}
}

// Generate all pull classes
.grid__col-xxs-pull--0 {
	margin-right: auto;
}

@for $i from 1 through $grid--columns {
	.grid__col-xxs-pull--#{$i} {
		margin-right: $i * 100% / $grid--columns;
	}
}

/*------------------------------------*\
		Smaller view
\*------------------------------------*/

@media #{md("smaller")} {

	[class*="grid__col-xs--"] {
		display: block;
	}

	.grid__col-xs--left  { float: left;   }
	.grid__col-xs--right { float: right;  }
	.grid__col-xs--0     { display: none; }

	// Generate all smaller classes
	@for $i from 1 through $grid--columns {
		.grid__col-xs--#{$i} {
			width: $i * 100% / $grid--columns;
		}
	}

	// Generate all push classes
	.grid__col-xs-push--0 {
		margin-left: auto;
	}

	@for $i from 1 through $grid--columns {
		.grid__col-xs-push--#{$i} {
			margin-left: $i * 100% / $grid--columns;
		}
	}

	// Generate all pull classes
	.grid__col-xs-pull--0 {
		margin-right: auto;
	}

	@for $i from 1 through $grid--columns {
		.grid__col-xs-pull--#{$i} {
			margin-right: $i * 100% / $grid--columns;
		}
	}
}



/*------------------------------------*\
		Small view
\*------------------------------------*/

@media #{md("small")} {

	[class*="grid__col-s--"] { display: block; }

	.grid__col-s--left  { float: left;   }
	.grid__col-s--right { float: right;  }
	.grid__col-s--0     { display: none; }

	// Generate all small classes
	@for $i from 1 through $grid--columns {
		.grid__col-s--#{$i} {
			width: $i * 100% / $grid--columns;
		}
	}

	// Generate all push classes
	.grid__col-s-push--0 {
		margin-left: auto;
	}

	@for $i from 1 through $grid--columns {
		.grid__col-s-push--#{$i} {
			margin-left: $i * 100% / $grid--columns;
		}
	}

	// Generate all pull classes
	.grid__col-s-pull--0 {
		margin-right: auto;
	}

	@for $i from 1 through $grid--columns {
		.grid__col-s-pull--#{$i} {
			margin-right: $i * 100% / $grid--columns;
		}
	}
}



/*------------------------------------*\
		Medium view
\*------------------------------------*/

@media #{md("medium")} {

	[class*="grid__col-m--"] { display: block; }

	.grid__col-m--left  { float: left;   }
	.grid__col-m--right { float: right;  }
	.grid__col-m--0     { display: none; }

	// Generate all medium classes
	@for $i from 1 through $grid--columns {
		.grid__col-m--#{$i} {
			width: $i * 100% / $grid--columns;
		}
	}

	// Generate all push classes
	.grid__col-m-push--0 {
		margin-left: auto;
	}

	@for $i from 1 through $grid--columns {
		.grid__col-m-push--#{$i} {
			margin-left: $i * 100% / $grid--columns;
		}
	}

	// Generate all pull classes
	.grid__col-m-pull--0 {
		margin-right: auto;
	}

	@for $i from 1 through $grid--columns {
		.grid__col-m-pull--#{$i} {
			margin-right: $i * 100% / $grid--columns;
		}
	}
}



/*------------------------------------*\
		Large view
\*------------------------------------*/

@media #{md("large")} {

	[class*="grid__col-l--"] { display: block; }

	.grid__col-l--left  { float: left;   }
	.grid__col-l--right { float: right;  }
	.grid__col-l--0     { display: none; }

	// Generate all large classes
	@for $i from 1 through $grid--columns {
		.grid__col-l--#{$i} {
			width: $i * 100% / $grid--columns;
		}
	}

	// Generate all push classes
	.grid__col-l-push--0 {
		margin-left: auto;
	}

	@for $i from 1 through $grid--columns {
		.grid__col-l-push--#{$i} {
			margin-left: $i * 100% / $grid--columns;
		}
	}

	// Generate all pull classes
	.grid__col-l-pull--0 {
		margin-right: auto;
	}

	@for $i from 1 through $grid--columns {
		.grid__col-l-pull--#{$i} {
			margin-right: $i * 100% / $grid--columns;
		}
	}
}



/*------------------------------------*\
		Larger view
\*------------------------------------*/

@media #{md("larger")} {

	[class*="grid__col-xl--"] { display: block; }

	.grid__col-xl--left  { float: left;   }
	.grid__col-xl--right { float: right;  }
	.grid__col-xl--0     { display: none; }

	// Generate all larger classes
	@for $i from 1 through $grid--columns {
		.grid__col-xl--#{$i} {
			width: $i * 100% / $grid--columns;
		}
	}

	// Generate all push classes
	.grid__col-xl-push--0 {
		margin-left: auto;
	}

	@for $i from 1 through $grid--columns {
		.grid__col-xl-push--#{$i} {
			margin-left: $i * 100% / $grid--columns;
		}
	}

	// Generate all pull classes
	.grid__col-xl-pull--0 {
		margin-right: auto;
	}

	@for $i from 1 through $grid--columns {
		.grid__col-xl-pull--#{$i} {
			margin-right: $i * 100% / $grid--columns;
		}
	}
}
