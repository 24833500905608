/*---------------------------------------*\
		Sélection
\*---------------------------------------*/

::-moz-selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}

::selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}



/*---------------------------------------*\
		Réglages globaux
\*---------------------------------------*/

*,
*:after,
*:before {
	position: relative;
	outline: none;
}

html {
	font-size: $font-size;

	// Media queries
	@media #{md("large")} {
		font-size: $font-size + 1;
	}

	@media #{md("larger")} {
		font-size: $font-size + 2;
	}

	@media #{md("largest")} {
		font-size: $font-size + 3;
	}
}

// Better font rendering for mac
.is-mac,
.is-mac input,
.is-mac button,
.is-mac textarea {
	@include antialiased;
}



/*------------------------------------*\
		Base typographique

		More settings:
		http://type-scale.com/
\*------------------------------------*/

// Include fontface
@include fontfaces($fontfaces, $font-dir);

body {
  font-family: $font-family-sans;
	font-size: 1em;
	line-height: $line-height;
	color: $font-color;
}

p,
ul,
ol,
blockquote {
	margin-bottom: $line-height * 1em;
}

h1,
h2,
h3,
h4 {
	margin: 1.414em 0 0.5em;
	font-family: $font-family-sans;
	line-height: 1.2;
	font-weight: 700;
}

h1 {
	margin-top: 0;
	font-size: 3.157em;
}

h2 {
	font-size: 2.369em;
}

h3 {
	font-size: 1.777em;
}

h4 {
	font-size: 1.333em;
}

small {
	font-size: 0.75em;
}

sup {
	vertical-align: super;
	font-size: 0.75em;
}

a {
	color: inherit;
}
